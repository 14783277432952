<template>
  <div>
    <v-dialog v-if="dialog" v-model="dialog" max-width="1000px" scrollable>
      <dialog-card
        title="Pontos de Rastreio (Fenômenos Tumorais)"
        @close="close"
      >
        <h3>Pares já adicionados ao diagnóstico</h3>
        <p class="mt-2 body-2">
          Selecione os pares que estão relacionados ao fenômeno tumoral
          selecionado
        </p>
        <pares-already-in-diagnose />
        <v-divider class="my-3" />
        <h3>
          Pares das associações patogênicas relacionadas ao FT selecionado
        </h3>
        <p class="mt-2 body-2">Rastreie os pares</p>
        <div
          v-for="(category, index) in categoriesWithTrackingPoints"
          :key="index"
        >
          <div class="subtitle-1 my-3">
            <span class="font-weight-bold">{{ category.categoryName }}</span>
          </div>
          <v-list>
            <template v-for="tp in category.trackingPoints">
              <step-item
                :key="tp.id"
                :title="tp.name"
                class="elevation-2"
                @click="selectTrackingPoint(tp, category)"
              />
            </template>
          </v-list>
        </div>
        <template #actions>
          <v-btn large outlined color="secondary" @click="submit">
            <v-icon class="mr-2">check</v-icon>
            Concluir
          </v-btn>
        </template>
      </dialog-card>
    </v-dialog>
    <impaction-points />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    ParesAlreadyInDiagnose: () =>
      import(
        "@/domains/therapy/steps/execute/domains/check-categories/presentation/dialogs/ParesAlreadyInDiagnose"
      ),
    StepItem: () =>
      import("@/domains/therapy/shared/presentation/components/StepItem"),
    ImpactionPoints: () => import("./ImpactionPointsDialog"),
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.checkCategories.trackingPoints;
      },
      set(value) {
        this.$store.commit("dialogs/setCheckCategoriesTrackingPoints", value);
      },
    },
    ...mapState({
      categoriesWithTrackingPoints: (state) =>
        state.checkCategories.categoriesWithTrackingPoints,
    }),
  },
  methods: {
    selectTrackingPoint(tp, category) {
      this.$store.commit("checkCategories/setSelectedTrackingPoint", tp);
      this.$store.commit("checkCategories/setSelectedCategory", category);
      this.$store.dispatch(
        "checkCategories/findImpactionPointsByTrackingPoint"
      );
    },
    selectParToEdit(par) {
      this.$store.dispatch("pares/editParAlreadyAddedToDiagnose", par.id);
    },
    submit() {
      this.$store.dispatch(
        "tumoralPhenomena/addRelatedParesOfSelectedToDiagnose"
      );
      this.close();
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
